<template>
    <LiefengContent class="vote">
        <template v-slot:title>题库管理</template>
        <template v-slot:toolsbarRight>
            <Button type="primary" @click="importExcel" icon="ios-add-circle-outline">导入</Button>
            <Button @click="exportExcel" type="error" icon="ios-cloud-download-outline">导出</Button>
            <Button @click="moreDel" type="success" icon="ios-trash-outline">批量删除</Button>
            <Button type="primary" @click="fanhuiFn">
                <Icon type="ios-arrow-back"></Icon>
                返回
            </Button>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :total="total"
                :curPage="currentPage"
                @hadlePageSize="pageChange"
                :loading="loading"
                :fixTable="true"
                :pagesizeOpts="[20, 30, 50, 100]"
                :pageSize="pageSize"
                @tableSelect="tableSelect"
            ></LiefengTable>

            <!-- 修改弹窗 -->
            <LiefengModal title="修改题目" :value="changeModalStatus" @input="changeModalFn">
                <template v-slot:contentarea>
                    <Form ref="titleForm" :model="titleData" :label-width="80" :rules="titleRule">
                        <FormItem label="题目" prop="title">
                            <Input v-model.trim="titleData.title" placeholder="请输入题目" :maxlength="20"></Input>
                        </FormItem>
                        <FormItem label="题目类型" prop="questionType">
                            <Select v-model="titleData.questionType">
                                <Option value="SINGLE">单选</Option>
                                <Option value="MULTIPLE">多选</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="题库分类" prop="questionBankId">
                            <Select style="width: 100%" :max-tag-count="3" v-model="titleData.questionBankId">
                                <Option :value="item.questionBankId" v-for="(item, index) in questionBankList" :key="index">{{ item.questionBankType }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="选项" prop="optionStr">
                            <Input :maxlength="200" v-model.trim="titleData.optionStr" type="textarea" show-word-limit :autosize="{ minRows: 2, maxRows: 5 }" placeholder="多个选项换行显示"></Input>
                        </FormItem>
                        <FormItem label="答案" prop="answerStr">
                            <Input :maxlength="20" v-model.trim="titleData.answerStr" placeholder="多个答案用逗号隔开"></Input>
                        </FormItem>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeModalFn(false)">取消</Button>
                    <Button type="primary" @click="modalSave" style="margin-left: 10px">保存</Button>
                </template>
            </LiefengModal>
            <!-- 导入excel弹窗 -->
            <LiefengModal title="导入题库数据" :value="excelStatus" @input="exportModalFn">
                <template v-slot:contentarea>
                    <Upload
                        ref="excelUpload"
                        type="drag"
                        :action="excelAction"
                        name="file"
                        :max-size="10240"
                        :show-upload-list="true"
                        :before-upload="beforeExcelUpload"
                        :on-success="myExcel"
                        :data="uploadData"
                        :headers="uploadHeaders"
                        :default-file-list="fileList"
                        :format="['xls', 'xlsx', 'xlsb', 'xlsm', 'xlst']"
                        :on-format-error="uploadFormatError"
                        :on-exceeded-size="uploadSizeError"
                    >
                        <div style="padding: 20px 0; text-align: center; width: 100%">
                            <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                            <p>点击上传，或将文件拖拽到此处</p>
                        </div>
                    </Upload>
                    <div v-if="textStatus">当前选择的文件名称:{{ excelFile.name }}</div>
                    <div v-html="errorContent"></div>
                    <div style="text-align: center; margin: 20px 0">
                        <Button type="primary" style="margin-right: 20px">
                            <a href="./excelfiles/question.xlsx" download="题库模板.xlsx" style="color: #fff">模板下载</a>
                        </Button>
                        <Button type="info" @click="submitExcel">确认导入</Button>
                    </div>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/answerquestion')
import { format } from "@/utils/formatTime"
import LiefengModal from "@/components/LiefengModal"
import LiefengContent from "@/components/LiefengContent"
import LiefengTable from "@/components/LiefengTable"

export default {
    data() {
        const optionValidator = (rule, value, callback) => {
            callback()
        }
        //验证答案
        const answerValidator = (rule, value, callback) => {
            let arr = this.titleData.optionStr.trim().split("\n")
            let optionArr = arr.filter(function (s) {
                return s && s.trim()
            })
            if (!value) {
                return callback(new Error("请填写答案"))
            } else {
                let answer = value.split(",")
                if (!answer.every(v => optionArr.includes(v))) {
                    return callback(new Error("选项中无此答案"))
                } else {
                    callback()
                }
            }
        }
        return {
            //题库修改验证
            titleRule: {
                title: [{ required: true, message: "请输入题目", trigger: "blur" }],
                questionType: [{ required: true, message: "请选择题目类型", trigger: "change" }],
                questionBankId: [{ required: true, message: "请选择题库类型", trigger: "change" }],
                optionStr: [{ required: true, validator: optionValidator }],
                answerStr: [{ required: true, validator: answerValidator, trigger: "blur" }],
            },
            //选中表格
            selectData: [],
            fileList: [],
            //导入文字显示标识
            textStatus: false,
            loading: false,
            excelAction: "",
            //excel导入错误信息
            errorContent: null,
            //修改弹窗
            changeModalStatus: false,

            talbeColumns: [
                {
                    type: "selection",
                    width: 60,
                    align: "center",
                },
                {
                    title: "编号",
                    key: "questionCode",
                    width: 110,
                    align: "center",
                },
                {
                    title: "题目",
                    key: "title",
                    align: "center",
                },
                {
                    title: "选项",
                    key: "optionsStr",
                    align: "center",
                },
                {
                    title: "答案",
                    key: "answerStr",
                    align: "center",
                },
                {
                    title: "题目类型",
                    key: "questionType",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h("span", params.row.questionType == "MULTIPLE" ? "多选" : "单选")
                    },
                },
                {
                    title: "题库分类",
                    key: "questionBankType",
                    width: 140,
                    align: "center",
                },
                {
                    title: "添加时间",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                },
                {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 150,
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    style: {
                                        marginRight: "10px",
                                    },
                                    on: {
                                        click: () => {
                                            this.changeRow(params.row)
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "Button",
                                {
                                    props: {
                                        type: "info",
                                        size: "small",
                                    },
                                    on: {
                                        click: () => {
                                            this.delTip = "该条"
                                            this.delRow(params.row)
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ])
                    },
                },
            ],
            tableData: [],
            total: 0,
            currentPage: 1,
            pageSize: 20,
            //excel导入弹窗
            excelStatus: false,
            //excel文件数据
            excelFile: null,
            //修改data
            titleData: {},
            uploadData: {},
            uploadHeaders: {},
            //题库类型
            questionBankList: [],

            delTip: "",
        }
    },
    methods: {
        //返回上一个页面
        fanhuiFn() {
            var index = parent.layer.getFrameIndex(window.name) //先得到当前iframe层的索引
            if (index) {
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.replace({
                    path: "/answerindex",
                    query: {
                        menuId: this.$route.query.menuId,
                    },
                })
            }
        },
        //导出excel
        exportExcel() {
            this.$Modal.confirm({
                title: "温馨提示",
                content: "<p>您正在导出当前页的数据，是否继续？（若要导出更多数据，请设置每页条数。）</p>",
                onOk: () => {
                    let tHeader = ["编号", "题目", "选项", "答案", "题目类型", "题库类型", "添加时间"]
                    let filterVal = ["questionCode", "title", "optionsStr", "answerStr", "questionType", "questionBankType", "gmtCreate"]
                    let tableData = JSON.parse(JSON.stringify(this.tableData))
                    tableData.map((item, index) => {
                        item.questionType = item.questionType == "MULTIPLE" ? "多选" : "单选"
                    })
                    this.$core.exportExcel(tHeader, filterVal, tableData, "题库列表")
                },
            })
        },
        //表格选中
        tableSelect(arr) {
            this.selectData = arr
        },
        //批量删除
        moreDel() {
            let idStr = ""
            if (this.selectData.length > 0) {
                this.selectData.map((item, index) => {
                    idStr += item.questionId + ","
                })
                idStr = idStr.substring(0, idStr.lastIndexOf(","))
                this.delTip = "已选择的"
                this.delRow({ questionId: idStr })
            } else {
                this.$Message.error({
                    background: true,
                    content: "请选择需删除的信息！",
                })
            }
        },
        changeModalFn(status) {
            this.changeModalStatus = status
        },
        exportModalFn(status) {
            this.excelStatus = status
        },
        //分页器
        pageChange(obj) {
            this.loading = true
            this.$get("/voteapi/api/pc/componentQuestion/selectQuestionInfoPage", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                questionBankId: this.$route.query.id,
                oemCode: parent.vue.oemInfo.oemCode,
                page: obj.page,
                pageSize: obj.pageSize,
            })
                .then(res => {
                    if (res.code == 200) {
                        //查询题库类型
                        this.total = res.maxCount
                        this.currentPage = res.currentPage
                        this.pageSize = res.pageSize

                        res.dataList.map((item, index) => {
                            this.questionBankList.map((cur, indexs) => {
                                if (cur.questionBankId == item.questionBankId) {
                                    item.questionBankId = cur.questionBankId
                                    item.questionBankType = cur.questionBankType
                                }
                            })
                            this.tableData = res.dataList
                            let optionsStr = ""
                            let answerStr = ""
                            item.options.map((items, indexs, arr) => {
                                optionsStr += items.content + "/"
                                if (items.answer == "1") {
                                    answerStr += items.content + "/"
                                }
                            })
                            this.tableData[index].gmtCreate = format(this.tableData[index].gmtCreate, "yyyy-MM-dd HH:mm:ss")
                            this.tableData[index].optionsStr = optionsStr.substring(0, optionsStr.lastIndexOf("/"))
                            this.tableData[index].answerStr = answerStr.substring(0, answerStr.lastIndexOf("/"))
                        })
                        this.loading = false
                    } else {
                        this.$Message.error({
                            background: true,
                            content: "获取题库信息失败！",
                        })
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取题库信息失败！",
                    })
                    this.loading = false
                })
        },
        //弹窗修改保存
        modalSave() {
            this.$refs.titleForm.validate(status => {
                console.log(status)
                if (status) {
                    var option = this.titleData.optionStr.replace(/\n/g, "<br/>")
                    var optionArr = option.split("<br/>")
                    var answerArr = this.titleData.answerStr.split(",")
                    let options = []
                    for (let k in optionArr) {
                        if (optionArr[k] == "") continue
                        if (answerArr.indexOf(optionArr[k]) != -1) {
                            options.push({
                                content: optionArr[k],
                                answer: 1,
                            })
                        } else {
                            options.push({
                                content: optionArr[k],
                                answer: 2,
                            })
                        }
                    }
                    this.titleData.options = options
                    this.$post("/voteapi/api/pc/componentQuestion/saveQuestionInfo", this.titleData, { "Content-Type": "application/json" }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                background: true,
                                content: "修改成功！",
                            })
                            this.pageChange({
                                page: this.currentPage,
                                pageSize: this.pageSize,
                            })
                            this.changeModalStatus = false
                        } else {
                            this.$Message.success({
                                background: true,
                                content: "修改失败！",
                            })
                        }
                    })
                }
            })
        },
        //修改
        changeRow(row) {
            this.$refs.titleForm.resetFields()
            this.titleData = JSON.parse(JSON.stringify(row))
            let optionStr = ""
            let answerStr = ""
            this.titleData.options.map((item, index) => {
                optionStr += item.content + "\n"
                if (item.answer == 1) {
                    answerStr += item.content + ","
                }
            })
            //去掉答案最后的逗号
            answerStr = answerStr.substring(0, answerStr.length - 1)
            this.titleData.optionStr = optionStr
            this.titleData.answerStr = answerStr
            this.changeModalStatus = true
        },
        delRow(row) {
            this.$Modal.confirm({
                title: "温馨提示",
                content: `<p>确定删除${this.delTip}题目？</p>`,
                onOk: () => {
                    this.$get("/voteapi/api/pc/componentQuestion/deleteQuestionInfo", {
                        questionId: row.questionId,
                    })
                        .then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "删除成功！",
                                })
                                this.pageChange({
                                    page: this.currentPage,
                                    pageSize: this.pageSize,
                                })
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: "删除失败！",
                                })
                            }
                        })
                        .catch(err => {
                            this.$Message.error({
                                background: true,
                                content: "删除失败！",
                            })
                        })
                },
            })
        },
        //导入按钮
        importExcel() {
            this.excelFile = null
            this.fileList = []
            this.textStatus = false
            this.excelStatus = true
        },

        //确认导入
        submitExcel() {
            if (this.excelFile) {
                this.$refs.excelUpload.post(this.excelFile)
            } else {
                this.$Message.error({
                    background: true,
                    content: "请先选择文件",
                })
            }
        },
        //导入excel格式错误
        uploadFormatError(file) {
            this.$Notice.warning({
                title: "文件格式不正确",
                desc: "文件" + file.name + "格式不正确，请上传xls,xlsx,xlsb,xlsm或xlst格式的文件",
            })
        },
        //导入excel文件大小超出限制
        uploadSizeError(file) {
            this.$Notice.error({
                title: "导入失败",
                desc: "文件 " + file.name + " 大小已超出限制，请控制在10M以内",
            })
        },
        //excel文件上传成功后执行
        myExcel(res) {
            if (res.code == 200) {
                this.$Message.success({
                    background: true,
                    content: "导入成功！",
                })
                this.textStatus = false
                this.pageChange({
                    page: this.currentPage,
                    pageSize: this.pageSize,
                })
                this.excelStatus = false
            } else {
                this.fileList = []
                this.$Message.error({
                    background: true,
                    content: res.desc || "导入失败,请按照模板格式填写数据！",
                })
            }
        },
        //上传之前
        beforeExcelUpload(file) {
            // console.log('proxy',window.vue.getProxy()["/old"].target);
            if(process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'testbuild' || process.env.NODE_ENV == 'dev' ){
               this.excelAction = 'http://liefeng-gateway.test.gongxiangplat.com/apps/oldpeople' + "/api/pc/componentQuestion/importQuestionInfos"
            }else{
                this.excelAction = 'https://api.gateway.prod.liefengtech.com/apps/oldpeople' + "/api/pc/componentQuestion/importQuestionInfos"
            }
            
            this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode
            this.uploadData.oemCode = parent.vue.oemInfo.oemCode
            this.excelFile = file
            this.uploadHeaders = {
                openId: window.sessionStorage.getItem("openId"),
                Authorization: window.sessionStorage.getItem("accessToken"),
            }
            this.textStatus = true
            return false
        },
    },
    components: {
        LiefengModal,
        LiefengContent,
        LiefengTable,
    },
    async beforeMount() {
        await this.$get("/voteapi/api/pc/componentQuestion/selectQuestionBank", {
            orgCode: parent.vue.loginInfo.userinfo.orgCode,
        }).then(res => {
            if (res.code == 200) {
                this.questionBankList = res.dataList
            }
        })
        this.pageChange({
            page: this.currentPage,
            pageSize: this.pageSize,
        })
    },
}
</script>

<style scoped lang='less'>
</style>
